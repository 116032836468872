class pxlThemeMyLogin {
  constructor( selector ) {
    let $ = document.querySelector(selector);
    
    this.el = {
      form: $.querySelector('form'),
      button: $.querySelector('form button')
    };
    
    if ( this.el.form ) this.listeners();
  }
  listeners() {
    var el = this.el;
    
    jQuery(document).ajaxSend(function(event, xhr, settings) {
      el.button.classList.add('button--sending');
      el.form.classList.add('form--sending');
    });
    
    jQuery(document).ajaxComplete(function(event, xhr, settings) {
      var alerts = jQuery('.tml-errors'), message = false;
      
      if ( !alerts.length ) alerts = jQuery('.tml-alerts');
      
      alerts.empty();
      
      if ( '/login/' == window.location.pathname ) {
        if ( !xhr.responseJSON.data.redirect ) {
          message = '<li class="tml-error">Login failed; Invalid user ID or password.</li>';
        }
      }
      else if ( '/lostpassword/' == window.location.pathname && !xhr.responseJSON.data.redirect ) {
        var input = el.form.querySelector('input#user_login');
        
        input.value = '';
        input.blur();
        
        message = '<li class="tml-message">If that email address is in our system, we will send you an email to reset your password.</li>';
      }
      
      if ( message ) {
        alerts.hide().append(message).fadeIn();
        alerts.fadeIn();
      }
      
      el.button.classList.remove('button--sending');
      el.form.classList.remove('form--sending');
      
      return false;
    });
  }
}